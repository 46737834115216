import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountUser } from '../_models/user';
import { Service, Price, Subscription } from '../_models/service';

import { AccountService } from '../_services/account.service'
import { CompanyService } from '../_services/company.service'
import { SubscriptionService } from '../_services/subscription.service';
import { MixpanelService } from '../_services/mixpanel.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

    @Input() hideHeader: boolean = false;
    @Input() companyId: number;
    @Input() accountUser: AccountUser;

    @Input() period: string = 'monthly';

    @Input() set slug(s: string) {
        this._slug = s;
        this.getService();
    }

    @Input() currency: string = 'USD';

    @Output() activated = new EventEmitter<boolean>();

    _slug: string = 'b-verified';

    subscriptionId: number | null;

    service: Service;
    latestSubscription: Subscription;
    prices: Price[];
    periods: Price[] = []; // price periods, containing distinct set of monthly, annually etc

    //serviceMonthly: Service;
    //serviceAnnually: Service;

    loggedInAccountUser: AccountUser | undefined | null;

    auIsCompanyUser: boolean = false;

    loading: boolean = true;

    totalSumIncVat: number;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private accountService: AccountService,
        private route: ActivatedRoute,
        public router: Router,
        private subscriptionService: SubscriptionService,
        private companyService: CompanyService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        //@Inject(LOCALE_ID) public locale: string
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get("serviceSlug")) {
                this._slug = params.get("serviceSlug") ?? '';
                this.getService();
            }
            if (params.get("subscriptionId")) {
                this.hideHeader = true;
                const subscriptionId = params.get("subscriptionId");
                this.subscriptionId = +(subscriptionId ?? 0);
                this.getServiceBySubscriptionId(this.subscriptionId);
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params["c"]) {
                this.companyId = +params["c"];
                //this.getCompany();
            }
        });
        this.getLoggedInAccountUser();
    }

    /*getLanguage() {
        if (navigator.language == 'sv' || this.locale == 'sv' || this.companyCountryCode == 'SE') {
            return 'sv';

        } else {
            return 'en';
        }

    }*/

    getTranslatedSlotName() {
        if (this.service && this.service.getSlotName()) {
            const slotName = this.service.getSlotName();
            return this.translate.instant(slotName);
        }
        return this.translate.instant('slots');
    }

    getLoggedInAccountUser(): void {
        this.loggedInAccountUser = this.accountService.loggedInAccountUserSig();

        const au = this.loggedInAccountUser;

        // check if loggedInAccountUser is a company admin
        if (this.companyId && au && (au.isCompanyAdminFor(this.companyId) || au.isCompanyManagerFor(this.companyId))) {
            this.auIsCompanyUser = true;
        } else {
            this.auIsCompanyUser = false;
        }

        if (au) {
            this.currency = au.getCurrencyCode();

            // mixpanel
            if (this.companyId) {
                this.mixpanelService.track('company viewed service', {
                    'service slug': this._slug,
                    //'company': this.company.getPageName(),
                    'company id': this.companyId,
                    'user': this.loggedInAccountUser?.getEmail(),
                });
            } else {
                this.mixpanelService.track('user viewed service', {
                    'service slug': this._slug,
                    'user': this.loggedInAccountUser?.getEmail(),
                });
            }

        }
    }

    /*isCompanyAdmin() {
        if (this.company && this.loggedInAccountUser.isCompanyAdminFor(this.company.getId())) {
            return true;
        }
        return false;
    }*/

    getServiceBySubscriptionId(subscriptionId: number) {
        this.loading = true;

        this.subscriptionService.getServiceBySubscriptionId(subscriptionId).subscribe(
            service => {
                this.service = service;
                this.prices = service.getPrices();
                this.latestSubscription = new Subscription().deserialize(service.getLatestSubscription());
                this.companyId = this.latestSubscription.getCompanyId();

                this.currency = this.latestSubscription.getCurrency();
                this.setPriceCurrency(this.currency);

                this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );
    }

    getService(): void {
        this.loading = true;

        let cId: number | undefined = undefined;
        let auId: number | undefined = undefined;

        if (this.companyId) {
            cId = this.companyId;
        }

        if (this.accountUser) {
            auId = this.accountUser.getId();
        }

        // NOTE that currency can be coming from the subscription component as the user selects country
        // make sure not to overwrite it with the currency checks below
        this.subscriptionService.getService(this._slug, '', this.currency, cId, auId).subscribe(
            s => {
                this.service = s;
                this.prices = s.getPrices();
                this.latestSubscription = new Subscription().deserialize(s.getLatestSubscription());

                // if the users currency is the same as one of the options of currencies for the service, select it
                let findPrice: Price | undefined = undefined;
                let findPriceStripe: Price | undefined = undefined;

                if (this.accountUser) {
                    findPrice = this.prices.find(p => p.currency == this.accountUser.getCurrencyCode());
                    findPriceStripe = this.prices.find(p => p.currency == this.accountUser.getStripeCustomerCurrency());
                }

                // if there is an active subscription, select the currency of the active subscription
                if (this.latestSubscription && this.latestSubscription.getCurrency()) {
                    this.currency = this.latestSubscription.getCurrency();

                // if not, select the currency of the user (stripe currency)
                } else if(this.accountUser && findPriceStripe) {
                    this.currency = this.accountUser.getStripeCustomerCurrency();

                } else if(s.selected_price && s.selected_price.currency) {
                    this.currency = s.selected_price.currency;

                // if not, select the currency of the user (this is third because the user might change currency)
                // NOTE this was removed as it was causing issues with the currency not being updated when the
                // user changed it in the subscription component
                //} else if(this.accountUser && findPrice) {
                //    currency = this.accountUser.getCurrencyCode();

                // if not, select USD as default
                } else if (!this.currency) {
                    this.currency = 'USD';
                }

                this.setPriceCurrency(this.currency);

                this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );

    }

    setPriceCurrency(currency: string) {
        const price = this.prices.find(p => p.currency == currency);

        if (price) {
            this.service.selected_price = price;
        }

        // to get the distinct prices by period(s) and the selected currency
        this.periods = this.prices.filter(price => price.currency === currency)
        .reduce((uniquePrices: Price[], price) => {
            if (!uniquePrices.find(p => p.period === price.period)) {
                uniquePrices.push(price);
            }
            return uniquePrices;
        }, []);

        // order the periods by annual first
        this.periods.sort((a, b) => {
            if (a.period === 'annually') {
                return -1;
            } else {
                return 1;
            }
        });

    }


    /*setService(s: Service) {
        this.service = s;
    }*/

    setPrice(p: Price) {
        this.service.selected_price = p;
    }

    setActivated() {
        this.accountService.reloadLoggedInAccountUser();
        this.activated.emit(true);
    }

    getSavings() {

        let monthlyPrice = this.service.prices.find(p => p.period == 'monthly' && p.currency == this.currency);
        let annuallPrice = this.service.prices.find(p => p.period == 'annually' && p.currency == this.currency);

        if (monthlyPrice && annuallPrice) {
            let monthlyPriceValue = monthlyPrice.price;
            let annuallPriceValue = annuallPrice.price;

            let savings = monthlyPriceValue * 12 - annuallPriceValue;
            return savings;
        }

        return 0;
    }

    getSavingsInPercent() {

        if (this.getSavings() > 0) {
            let monthlyPrice = this.service.prices.find(p => p.period == 'monthly' && p.currency == this.currency);

            if (monthlyPrice) {
                let savingsInPercent = this.getSavings() / (monthlyPrice.price * 12) * 100;
                return savingsInPercent;
            }
        }

        return 0;
    }

    getLanguage() {
        if (isPlatformBrowser(this.platformId)) {
            let localStorageLang = localStorage.getItem('currentLang');
            const browserLang = this.translate.getBrowserLang();

            if (localStorageLang) {
                return localStorageLang;

            } else if (browserLang) {
                return browserLang;

            } else {
                return 'en';
            }
        }
        return 'en';
    }

    getElementLanguage(element: any) {
        if (!element)
            return undefined;

        if (isPlatformBrowser(this.platformId)) {
            let localStorageLang = localStorage.getItem('currentLang');
            const browserLang = this.translate.getBrowserLang();

            if (localStorageLang && element.hasOwnProperty(localStorageLang)) {
                return localStorageLang;

            } else if (browserLang && element.hasOwnProperty(browserLang)) {
                return browserLang;

            } else if (element.en) {
                return 'en';

            } else {
                return undefined;
            }
        }
        return undefined;
    }

}
